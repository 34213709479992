import React from "react"
import { Link } from "gatsby"
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import FolderIcon from '@material-ui/icons/Folder';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';


const FileList = (props) => {

    const getDirectories = () => {
        let array = props.filelist.map(d => d.relativeDirectory);
        array = array.filter((a, b) => array.indexOf(a) === b);
        array.sort();
        return array ? array : [];
    }

    const getFiles = () => {
        return props.filelist.filter(f => f.relativeDirectory === decodeURIComponent(props.currentFolder).replace(/^\/|\/$/g, ''));
    }

    return (
        <List>
            {props.currentFolder === "/" && getDirectories().map((dir, index) => (
                <ListItem key={index} button component={Link} to={`/${dir}`} >
                    <ListItemIcon>
                        <FolderIcon />
                    </ListItemIcon>
                    <ListItemText primary={dir} />
                </ListItem>
            ))}
            {props.currentFolder !== "/" &&
                <ListItem button component={Link} to="/">
                    <ListItemIcon>
                        <ArrowBackIcon />
                    </ListItemIcon>
                    <ListItemText primary="Parent directory" />
                </ListItem>
            }
            {props.currentFolder !== "/" && getFiles().map((file, index) => (
                <ListItem key={index} button component="a" href={"/downloads/" + file.relativePath}>
                    <ListItemIcon>
                        <InsertDriveFileIcon />
                    </ListItemIcon>
                    <ListItemText primary={file.base} />
                </ListItem>
            ))}
        </List>
    );
}

export default FileList
