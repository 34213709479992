import React from "react"
import { graphql, useStaticQuery, Link } from 'gatsby'
import SEO from '../components/seo'
import FileList from "../components/filelist"

import makeStyles from '@material-ui/core/styles/makeStyles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import List from '@material-ui/core/List'
import Drawer from '@material-ui/core/Drawer'
import Divider from '@material-ui/core/Divider'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Hidden from '@material-ui/core/Hidden'
import Box from '@material-ui/core/Box'

import CloudIcon from '@material-ui/icons/Cloud'
import HomeIcon from '@material-ui/icons/Home'
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight'

const drawerWidth = 300;
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawerNav: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  subDirectory: {
    width: drawerWidth - 120,
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  tittle: {
    marginLeft: '10px',
  },
  backgroundIcon: {
    color: 'white',
  },
  foregroundIcon: {
    color: '#1976d2',
  }
}));

const IndexPage = ({ location }) => {
  const data = useStaticQuery(graphql`
    query downloadQuery {
        allFile(filter: {sourceInstanceName: {eq: "downloads"}}) {
            edges {
                node {
                relativeDirectory
                relativePath
                size
                ctime
                prettySize
                base
                }
            }
        }
    }
    `)


  const filelist = data.allFile.edges.map(e => {
    return e.node;
  });

  const currentFolder = location.pathname;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <SEO />
      {currentFolder === '/'
        ? <SEO />
        : <SEO title= {currentFolder.replace(/^\/|\/$/g, '')} />
      }
      <AppBar position="fixed" className={classes.appBar}>
        <Hidden smUp implementation="css">
          <Toolbar>
            <IconButton component={Link} to="/">
              <CloudIcon className={classes.backgroundIcon} />
              <Typography variant="h5" noWrap className={classes.tittle}>
                <Box fontWeight="fontWeightBold" color="white">
                  EVCC Repository
                </Box>
              </Typography>
            </IconButton>
          </Toolbar>
        </Hidden>
        <Toolbar>
          <Typography variant="h5" color="inherit" noWrap>
            {decodeURIComponent(currentFolder)}
          </Typography>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawerNav}>
        <Hidden xsDown implementation="css">
          <Drawer variant="permanent" classes={{ paper: classes.drawerPaper }}>
            <IconButton component={Link} to="/">
              <CloudIcon className={classes.foregroundIcon}/>
              <Typography variant="h5" color="inherit" noWrap className={classes.tittle}>
                EVCC Repository
                </Typography>
            </IconButton>
            <Divider />
            <List>
              <ListItem button component={Link} to="/">
                <ListItemIcon>
                  <HomeIcon />
                </ListItemIcon>
                <ListItemText primary="Home" />
              </ListItem>
              {currentFolder !== '/' &&
                <ListItem button className={classes.nested}>
                  <ListItemIcon>
                    <SubdirectoryArrowRightIcon />
                  </ListItemIcon>
                  <ListItemText primary={decodeURIComponent(currentFolder)} className={classes.subDirectory} />
                </ListItem>
              }
            </List>
          </Drawer>
        </Hidden>
      </nav>
      <Paper elevation={3} className={classes.content}>
        <Hidden smUp implementation="css">
          <div className={classes.toolbar} />
        </Hidden>
        <div className={classes.toolbar} />

        <FileList filelist={filelist} currentFolder={currentFolder} />
      </Paper>
    </div>

  );

}






export default IndexPage
